import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../graphql/generated";
import currentUserReducer from "./currentUserSlice";
import initialRequestReducer from "./initialRequestSlice";
import currentPatientReducer from "./currentPatientSlice";
import currentClinicReducer from "./currentClinicSlice";
import visitReducer from "./visitSlice";
import recordingListReducer from "./recordingListSlice";
import assessmentReducer from "./assessmentSlice";

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  initialRequest: initialRequestReducer,
  visit: visitReducer,
  recordingList: recordingListReducer,
  currentPatient: currentPatientReducer,
  assessment: assessmentReducer,
  currentClinic: currentClinicReducer,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
