import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuIcon from "@mui/icons-material/Menu";

function SimpleHeader() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <Stack width="100%">
      <Stack direction="row" ml="10px" mr="30px">
        <IconButton onClick={openMenu}>
          <MenuIcon />
        </IconButton>

        <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
          <MenuItem onClick={() => navigate("/greeter-dashboard")}>
            <ListItemText>Greeter</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/medassist-dashboard")}>
            <ListItemText>Med Assistant</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/physician-dashboard")}>
            <ListItemText>Physician</ListItemText>
          </MenuItem>
        </Menu>
        <Stack direction="row" sx={{ mx: "auto", my: "6px" }}>
          <Typography
            variant="h1"
            marginRight={"0.2em"}
            color={theme.palette.primary.main}
          >
            Sehat
          </Typography>
          <Typography variant="h1" color={theme.palette.secondary.main}>
            Sanjha
          </Typography>
        </Stack>
      </Stack>
      <Divider color={theme.palette.primaryContainer.main} />
    </Stack>
  );
}

export default SimpleHeader;
