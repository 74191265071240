import { useIntl } from "react-intl";
import { Box, Stack, useTheme } from "@mui/material";

function TagsSectionView() {
  const intl = useIntl();

  return (
    <Stack
      display="flex"
      direction="row"
      columnGap={2}
      rowGap={2}
      sx={{ flexWrap: "wrap" }}
      justifyContent="start"
      alignItems="center"
    >
      <TagCompView name={intl.formatMessage({ id: "tags.Voilent" })} />
      <TagCompView name={intl.formatMessage({ id: "tags.Urgent" })} />
    </Stack>
  );
}

function TagCompView({ name }: { name: string }) {
  const theme = useTheme();
  return (
    <Box
      borderRadius={3}
      px={2}
      py={0.7}
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.infoBox.main,
        color: theme.palette.themeText.main,
      }}
    >
      {name}
    </Box>
  );
}

export default TagsSectionView;
