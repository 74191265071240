import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  ClinicWebUserInfo,
  useUserForSessionQuery,
} from "../../graphql/generated";
import { setClinic } from "../../state/currentClinicSlice";
import LoginPageLayoutView from "../../components/LoginPageLayoutView";
function SelectClinicRoute() {
  const { data } = useUserForSessionQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useTheme();

  const themeTextLight = theme.palette.themeText.light;
  const themeTextDark = theme.palette.themeText.dark;

  const clinics = data?.userForSession?.clinics;

  // in case there's only one clinc
  if (clinics?.length === 1) {
    if (clinics[0]) {
      // checking if it's null or not
      dispatch(setClinic(clinics[0]));
      navigate("/greeter-dashboard");
    }
  }

  function selectClinic(clinic: ClinicWebUserInfo | null) {
    if (clinic) {
      dispatch(setClinic(clinic));
      navigate("/greeter-dashboard");
    }
  }

  return (
    <LoginPageLayoutView title={intl.formatMessage({ id: "clinics.title" })}>
      <LoginPageLayoutView.Header>
        <Typography variant="h1" align="left">
          {data?.userForSession?.username}
        </Typography>
        <Typography align="left">Please select your clinic</Typography>
      </LoginPageLayoutView.Header>

      <LoginPageLayoutView.Primary>
        <SelectClinicUiView clinics={clinics} selectClinic={selectClinic} />
      </LoginPageLayoutView.Primary>
      <LoginPageLayoutView.Secondary>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ color: themeTextLight }}>
            Can't find a Clinic?
          </Typography>
          <Typography
            sx={{
              color: themeTextDark,
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Add a new one
          </Typography>
        </Stack>
      </LoginPageLayoutView.Secondary>
    </LoginPageLayoutView>
  );
}
type selectClinicCallback = (clinic: ClinicWebUserInfo | null) => void;
interface ClinicUiViewProps {
  clinics: (ClinicWebUserInfo | null)[] | undefined | null;
  selectClinic: selectClinicCallback;
}

export const SelectClinicUiView = ({
  clinics,
  selectClinic,
}: ClinicUiViewProps) => {
  const theme = useTheme();
  const neutralBackground = theme.palette.neutralBackground.main;
  const hoverBackground = theme.palette.hoverBackground.main;
  const themeText = theme.palette.themeText.main;
  return (
    <>
      {clinics?.map((clinic, key) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "12px",
            backgroundColor: neutralBackground,
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: hoverBackground,
              cursor: "pointer",
            },
            padding: "6px 8px",
            color: themeText,
          }}
          width="100%"
          onClick={() => selectClinic(clinic)}
          key={key}
        >
          <Stack>
            <Typography variant="h1" textAlign="left">
              {clinic?.name}
            </Typography>
            <Typography textAlign="left">
              {clinic?.city} {clinic?.province}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ display: "flex" }} spacing={1}>
            <Typography variant="caption">Last Signed in: </Typography>
            <Typography variant="body2">40 Minutes Ago </Typography>
          </Stack>
        </Box>
      ))}
    </>
  );
};
export default SelectClinicRoute;
