import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import { type RootState } from "../../state/reducers";
import { LoginState } from "../../state/currentUserSlice";
import { performLogin } from "../../middleware/authMiddleware";
import { AppDispatch } from "../../state/store";
import { textChangeHandler } from "../../utils/eventUtils";
//import useCurrentRole from "../../hooks/useCurrentRole";
import LoginPageLayoutView from "../../components/LoginPageLayoutView";

type LoginCallback = (u: string, p: string) => void;

interface LoginUiViewProps {
  loginInProgress: boolean;
  onLogin: LoginCallback;
  username: string;
  setUsername: (u: string) => void;
  password: string;
  setPassword: (p: string) => void;
}

function LoginUiView({
  loginInProgress,
  onLogin,
  username,
  setUsername,
  password,
  setPassword,
}: LoginUiViewProps) {
  const onClick = () => {
    onLogin(username, password);
  };
  const loginInfoEntered = username.length > 0 && password.length > 0;

  return (
    <LoginPageLayoutView title="">
      <LoginPageLayoutView.Primary>
        <TextField
          sx={{ width: "100%" }}
          required
          id="filled-required"
          label="Username"
          value={username}
          disabled={loginInProgress}
          onChange={textChangeHandler(setUsername)}
        />
        <TextField
          sx={{ width: "100%" }}
          required
          id="filled-required"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          disabled={loginInProgress}
          onChange={textChangeHandler(setPassword)}
        />
      </LoginPageLayoutView.Primary>
      <LoginPageLayoutView.Secondary>
        <Typography>Don't have an account? TBD</Typography>
      </LoginPageLayoutView.Secondary>
      <LoginPageLayoutView.Actions>
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          onClick={onClick}
          disabled={loginInProgress || !loginInfoEntered}
        >
          Login
        </Button>
      </LoginPageLayoutView.Actions>
      {loginInProgress && <CircularProgress />}
    </LoginPageLayoutView>
  );
}

// const landingPageForRole: { [key: string]: string } = {
//   user: "/record",
//   trn: "/recordings",
//   su: "/recordings",
// };

export default function LoginRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // const role = useCurrentRole();
  const intl = useIntl();

  const loginState = useSelector(
    (state: RootState) => state.currentUser.loginState
  );

  const handleLoginFailed = () => {
    setPassword("");
    enqueueSnackbar(intl.formatMessage({ id: "login.failed" }), {
      variant: "error",
    });
  };

  const doLogin = async (username: string, password: string) => {
    const result = await dispatch(performLogin({ username, password }));
    if (performLogin.fulfilled.match(result)) {
    }
    if (result.payload) {
      navigate("/select-clinic");
    } else {
      handleLoginFailed();
    }
  };

  return (
    <LoginUiView
      loginInProgress={loginState === LoginState.LoggingIn}
      onLogin={(username, password) => {
        void doLogin(username, password);
      }}
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
    />
  );
}
