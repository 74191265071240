import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { type AppDispatch } from "../state/store";
import { performLogout } from "../middleware/authMiddleware";
import useCurrentRole from "../hooks/useCurrentRole";

interface AuthorizedNavigationItemProps {
  path: string;
  allowedRoles: string[];
  children: React.ReactNode;
}

function AuthorizedNavigationItem({
  path,
  allowedRoles,
  children,
}: AuthorizedNavigationItemProps) {
  const role = useCurrentRole();
  if (!allowedRoles.includes(role || "")) {
    return null;
  }
  return (
    <ListItem>
      <ListItemButton component={Link} to={path}>
        {children}
      </ListItemButton>
    </ListItem>
  );
}
const MainMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const doLogout = () => {
    void dispatch(performLogout({}));
  };
  return (
    <Box sx={{ mt: 7 }}>
      <List>
        <AuthorizedNavigationItem path="/record" allowedRoles={["user", "su"]}>
          <ListItemText>Make Recording</ListItemText>
        </AuthorizedNavigationItem>
        <AuthorizedNavigationItem
          path="/recordings"
          allowedRoles={["trn", "su"]}
        >
          <ListItemText>Recordings</ListItemText>
        </AuthorizedNavigationItem>
        <AuthorizedNavigationItem
          path="/site-notes"
          allowedRoles={["trn", "su", "smgr"]}
        >
          Site Notes
        </AuthorizedNavigationItem>
        <AuthorizedNavigationItem path="/create-user" allowedRoles={["su"]}>
          <ListItemText>Create User</ListItemText>
        </AuthorizedNavigationItem>
        <Divider />
        <ListItem>
          <ListItemButton onClick={doLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText>
              <ThemedNavLink to="visits">
                <FormattedMessage id="visits" />
              </ThemedNavLink>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText>
              <ThemedNavLink to="data">
                <FormattedMessage id="data" />
              </ThemedNavLink>
            </ListItemText>
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );
};

export default MainMenu;
