import * as R from "ramda";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import useMemoizedRemoveNils from "../../hooks/useMemoizedRemoveNils";
import { usePatientMedicationQuery } from "../../graphql/generated";
import { RootState } from "../../state/reducers";
import { MedicationInfo } from "../visitInProgress/VisitInteractionPanel";
import AmchurCard from "../../components/AmchurCard";
import TextAutocompleteField from "../../components/TextAutocompleteField";

const tradenameOptions = ["A", "B"];

function PlanRoute() {
  const { visit, assessmentData } = useSelector((state: RootState) => {
    return { visit: state.visit, assessmentData: state.assessment };
  });
  const { notes, assessment, diagnoses, symptoms } = assessmentData;

  const { data: medicationData } = usePatientMedicationQuery({
    id: visit.visit?.patientId || "",
    patientIdType: visit.visit?.patientIdType || "",
  });
  const medications = useMemoizedRemoveNils(
    medicationData?.patient?.medications
  );
  console.log(`medications: ${JSON.stringify(medications)}`);

  return (
    <PlanUIView
      medications={medications}
      notes={notes}
      assessment={assessment}
      diagnoses={diagnoses || []}
      symptoms={symptoms}
    />
  );
}

interface PlanUiViewProps {
  medications: MedicationInfo[];
  notes?: string;
  assessment: Record<string, string>;
  diagnoses: string[];
  symptoms: string[];
}

function PlanUIView({
  medications,
  notes,
  assessment,
  diagnoses,
  symptoms,
}: PlanUiViewProps) {
  return (
    <Stack spacing="1em" mx="2em" alignItems="flex-start" mt="1em">
      <DiagnosisPanel
        notes={notes || "These are the notes"}
        symptoms={symptoms}
      />
      <Typography variant="h3">
        <FormattedMessage id="planPage.diagnosis" />
      </Typography>
      <AssesmentPanel diagnoses={diagnoses} />
      {/* <AssesmentPanel diagnoses={["flu", "cold", "pancreatic cancer"]} /> */}
      <Typography variant="h3">Plan</Typography>
      <MedicationsPanel
        assessment={assessment}
        //assessment={{"tea": "Drink Earl Gray", "rest": "Take a nap"}}
        medications={medications || []}
      />
    </Stack>
  );
}

interface DiagnosisPanelProps {
  notes?: string;
  symptoms: string[];
}

export function SymptomChip({ text }: { text: string }) {
  return (
    <Grid xs={"auto"}>
      <Item>
        <Chip label={text} color="primary" variant="outlined" />
      </Item>
    </Grid>
  );
}

function DiagnosisPanel({ notes, symptoms }: DiagnosisPanelProps) {
  const intl = useIntl();
  return (
    <AmchurCard
      title={intl.formatMessage({ id: "planPage.doctorNote" })}
      role="primary"
    >
      <Stack alignItems="flex-start">
        <Grid container spacing={2} mb="5px">
          {symptoms.map((symptom) => (
            <SymptomChip text={symptom} key={symptom} />
          ))}
        </Grid>

        {notes && (
          <>
            <Typography textAlign="left">{notes}</Typography>
          </>
        )}
      </Stack>
    </AmchurCard>
  );
}

interface AssessmentPanelProps {
  diagnoses: string[];
}

function AssesmentPanel({ diagnoses }: AssessmentPanelProps) {
  const intl = useIntl();
  return (
    <Stack direction="row" spacing="1em" width="100%">
      {diagnoses.map((diagnosis, ix) => (
        <Box width="300px">
          <AmchurCard
            title={intl.formatMessage(
              { id: "planPage.problem" },
              { id: ix + 1 }
            )}
            role="secondary"
            titleAlign="center"
          >
            <Typography textAlign="left">{diagnosis}</Typography>
          </AmchurCard>
        </Box>
      ))}
    </Stack>
  );
}

interface MedicationsPanelProps {
  medications: MedicationInfo[];
  assessment: Record<string, string>;
}

function MedicationsPanel({ medications, assessment }: MedicationsPanelProps) {
  const assessmentList = R.toPairs(assessment);

  return (
    <>
      <AmchurCard title="Plan" role="secondary">
        <Stack justifyItems="flex-start">
          {assessmentList.map(([title, info]) => (
            <>
              <Typography
                variant="h6"
                textAlign="left"
                textTransform="capitalize"
              >
                {title}
              </Typography>
              <Typography textAlign="left" mb="5px">
                {info}
              </Typography>
            </>
          ))}

          {medications.map((medication) => (
            <MedicationRow medication={medication} key={medication.name} />
          ))}
        </Stack>
      </AmchurCard>
      <AmchurCard title="Medications" role="secondary">
        <EditMedicationRow />
        <Stack direction="row" justifyContent="flex-start">
          <Button>Add Medication</Button>
        </Stack>
      </AmchurCard>
    </>
  );
}

interface MedicationRowProps {
  medication: MedicationInfo;
}

const saltSize = "30%";
const tradeSize = "30%";
const strengthSize = "20%";
const dosageSize = "20%";

function MedicationRow({ medication }: MedicationRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography textAlign="left" width={saltSize} sx={{ fontWeight: "bold" }}>
        {medication.name}
      </Typography>
      <Typography textAlign="right" width={dosageSize} mr="10px"></Typography>
    </Stack>
  );
}

function EditMedicationRow() {
  return (
    <Stack direction="row" justifyContent="space-between">
      <TextField
        label="Salt name"
        value=""
        size="small"
        sx={{ width: saltSize, mr: "20px" }}
      />
      <TextAutocompleteField
        label="Trade name"
        choices={tradenameOptions}
        value=""
        setValue={() => {}}
        sx={{ marginTop: "0", width: tradeSize, mr: "20px" }}
        size="small"
      />

      <TextField
        label="Strength"
        value=""
        size="small"
        sx={{ width: strengthSize, mr: "20px" }}
      />
      <TextField
        label="Dosage"
        value=""
        size="small"
        sx={{ width: dosageSize }}
      />
    </Stack>
  );
}

export default PlanRoute;
