export const recordingsByCriteriaQuery = /* GraphQL */ `
  query recordingsByCriteria($criteria: RecordingQueryInput!) {
    recordings(criteria: $criteria) {
      recordingId
      recordedAt
      user
      patientId
      status
      assignedTo
      originalTranscription
      correctedTranscription
      altTranscription
      notes
    }
  }
`;

export const recordingDownloadUrlQuery = /* GraphQL */ `
  query recording($id: String!) {
    recording(id: $id) {
      downloadUrl
    }
  }
`;

export const recordingsDownloadUrlsQuery = /* GraphQL */ `
  query recordingsById($ids: [String]!) {
    recordingsById(ids: $ids) {
      downloadUrl
    }
  }
`;
