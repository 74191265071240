interface RecordingTools {
  mediaRecorder: MediaRecorder | null;
  mediaStream: MediaStream | null;
  //cancelled: boolean;
}

type StateCallback = (active: boolean) => void;
type DataCallback = (data: string) => Promise<void>;
type FailureCallback = (error: Error) => void;

interface Recorder {
  start(): Promise<void>;
  stop(): void;
  cancel(): void;
}

class RealRecorder implements Recorder {
  mediaRecorder: MediaRecorder | null;
  mediaStream: MediaStream | null;
  cancelled: boolean;
  started: boolean;
  onStateChange: StateCallback;
  onDataReceived: DataCallback;
  onFailure: FailureCallback;
  //parts: Blob[] = [];
  timer: number | undefined;

  constructor(
    onStateChange: StateCallback,
    onDataReceived: DataCallback,
    onFailure: FailureCallback
  ) {
    this.mediaRecorder = null;
    this.mediaStream = null;
    this.cancelled = false;
    this.started = false;
    this.onStateChange = onStateChange;
    this.onDataReceived = onDataReceived;
    this.onFailure = onFailure;
  }

  async start(timeslice?: number): Promise<void> {
    const mediaTools = await createMediaRecorder();
    this.mediaRecorder = mediaTools.mediaRecorder;
    this.mediaStream = mediaTools.mediaStream;
    this.started = true;
    //this.parts = [];

    if (this.mediaRecorder != null) {
      this.onStateChange(true);
      this.mediaRecorder.start();
      this.mediaRecorder.onerror = (e) => {
        console.log(`ERROR: ${JSON.stringify(e)}`);
      };

      this.mediaRecorder.ondataavailable = (e) => {
        const reader = new FileReader();
        console.log(`size of blob is ${e.data.size}`);
        // this.parts.push(e.data);

        // const omniBlob = new Blob(this.parts, { type: "audio/webm" });
        reader.readAsDataURL(e.data);
        reader.onloadend = () => {
          console.log(`DATA URL : ${reader.result as string}`);
          if (this.cancelled) {
            return;
          }
          if (reader.result == null) {
            this.onFailure(new Error("No data received"));
          }
          void this.onDataReceived(reader.result as string);
        };
      };

      if (timeslice) {
        this.timer = window.setInterval(() => {
          this.mediaRecorder?.stop();
          this.mediaRecorder?.start();
          console.log("RECORDER: restarting recorder");
        }, timeslice);
      }
    }
  }

  stop() {
    this.onStateChange(false);
    this.mediaRecorder?.stop();
    this.mediaStream?.getAudioTracks()[0].stop();
    clearInterval(this.timer);
    this.timer = undefined;
    setTimeout(() => {
      this.started = false;
    }, 500);
  }
  cancel() {
    this.cancelled = true;
    this.stop();
  }
}
async function createMediaRecorder(): Promise<RecordingTools> {
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });
  console.log(`media stream is ${JSON.stringify(mediaStream)} on creation`);
  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType: "audio/webm",
    audioBitsPerSecond: 128000, // don't know if this is used.
  });
  return { mediaRecorder, mediaStream };
}

function createRecorder(
  onStateChange: StateCallback,
  onDataReceived: DataCallback,
  onFailure: FailureCallback
) {
  console.log("RECORDER: creating recorder");
  return new RealRecorder(onStateChange, onDataReceived, onFailure);
}

export default createRecorder;
export type { Recorder, StateCallback, DataCallback, FailureCallback };
