import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import CancelIcon from "@mui/icons-material/Cancel";
import { uploadRecordingMiddleware } from "../../middleware/recordingMiddleware";
import { AppDispatch } from "../../state/store";
import useRecorder from "../../hooks/useRecorder";
import { type UploadRecordingParams } from "../../middleware/recordingMiddleware";
import { toRawCnic } from "../../domain/cnicUtils";
import useCurrentRole from "../../hooks/useCurrentRole";
import useInitialParams from "../../hooks/useInitialParams";
import CenteredForm from "../../components/CenteredForm";
import PatientIdField from "../../components/PatientIdField";

export default function RecordRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const { getInitial, clearInitial } = useInitialParams();
  const { patientId: initialPatientId, start } = getInitial();
  const [patientId, setPatientId] = useState(toRawCnic(initialPatientId || ""));

  const onFailure = useCallback(() => {
    enqueueSnackbar("Recording failed", { variant: "error" });
  }, []);

  const uploadRecordingData = useCallback(
    async (data: string) => {
      const args: UploadRecordingParams = {
        recordingDataURI: data,
        patientId: patientId,
        patientIdType: "PKCNI",
      };
      const result = await dispatch(uploadRecordingMiddleware(args));
      if (uploadRecordingMiddleware.fulfilled.match(result)) {
        enqueueSnackbar("Recording saved", { variant: "success" });
      } else {
        onFailure();
      }
    },
    [dispatch, onFailure, patientId]
  );

  const { recorder, recordingInProgress } = useRecorder(
    uploadRecordingData,
    onFailure
  );

  // if we have ?start=1 in the url, start right away.
  useEffect(() => {
    if (start === "1" && patientId) {
      void recorder.start();
    }
  }, [start, recorder, patientId]);
  useEffect(clearInitial, [clearInitial]);

  const role = useCurrentRole();
  const isSu = role === "su";

  return (
    <RecordUiRoute
      recordingInProgress={recordingInProgress}
      isSu={isSu}
      patientId={patientId}
      setPatientId={setPatientId}
      onStart={() => void recorder.start()}
      onStop={() => recorder.stop()}
      onCancel={() => recorder.cancel()}
    />
  );
}

function NowRecording() {
  return (
    <Typography variant="h6" color="secondary">
      Recording in progress
    </Typography>
  );
}

function NotYetRecording() {
  return <Typography variant="h6">Not recording</Typography>;
}

function CreateUserLink() {
  return (
    <>
      <Box height="10px" />
      <Link to="/create-user">Create User</Link>
      <Link to="/recordings">List of Recordings</Link>
    </>
  );
}

interface RecordUiViewProps {
  recordingInProgress: boolean;
  isSu: boolean;
  onStart: () => void;
  onStop: () => void;
  onCancel: () => void;
  patientId: string;
  setPatientId: (patientId: string) => void;
}

function RecordUiRoute({
  recordingInProgress,
  isSu,
  patientId,
  setPatientId,
  onStart,
  onStop,
  onCancel,
}: RecordUiViewProps) {
  const cnicProvided = patientId.length > 0;

  return (
    <CenteredForm>
      <PatientIdField
        patientId={patientId}
        setPatientId={setPatientId}
        disabled={recordingInProgress}
        required
      />
      <Box height="10px" />
      {recordingInProgress && <NowRecording />}
      {!recordingInProgress && <NotYetRecording />}
      <Box height="10px" />
      <Button
        variant="contained"
        onClick={onStart}
        color="secondary"
        disabled={recordingInProgress || !cnicProvided}
      >
        <PlayIcon />
        Start
      </Button>

      <Button
        variant="contained"
        onClick={onStop}
        disabled={!recordingInProgress}
      >
        <StopIcon />
        Stop
      </Button>
      <Box height="10px" />
      <Button
        variant="contained"
        onClick={onCancel}
        color="error"
        disabled={!recordingInProgress}
      >
        <CancelIcon />
        Cancel
      </Button>
      {isSu && <CreateUserLink />}
    </CenteredForm>
  );
}
