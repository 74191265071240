import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { nullary } from "shared-utils";
import AmchurCard from "../../components/AmchurCard";
import { type Patient, type PatientId } from "../../graphql/generated";
import { toFormattedCnic } from "../../domain/cnicUtils";
import PatientIdField from "../../components/PatientIdField";

export type PatientList = Partial<Patient>[];

interface GreeterDashboardUiViewProps {
  allPatients: PatientList;
  searchedPatients: PatientList;
  startVisit: (patientId: PatientId) => void;
  onPatientSearch: (patientId: PatientId) => void;
  onPatientAdd: (id: string) => void;
}

function GreeterDashboardUiView({
  allPatients,
  searchedPatients,
  startVisit,
  onPatientSearch,
  onPatientAdd,
}: GreeterDashboardUiViewProps) {
  const intl = useIntl();
  return (
    <Stack direction="row" spacing={2} mt="10px" mx="2em">
      <Stack width="67%" spacing="20px">
        <Typography variant="h3">
          {" "}
          <FormattedMessage id="greeterDashboard.listHeader" />
        </Typography>
        <AmchurCard
          title={intl.formatMessage({ id: "greeterDashboard.walkinSubheader" })}
          role="secondary"
        >
          <GreeterSearchView
            searchedPatients={searchedPatients}
            onPatientSearch={onPatientSearch}
            onPatientAdd={onPatientAdd}
          />
        </AmchurCard>
        <AmchurCard
          title={intl.formatMessage({
            id: "greeterDashboard.scheduleSubheader",
          })}
          role="secondary"
        >
          <AllPatientsListView
            allPatients={allPatients}
            startVisit={startVisit}
          />
        </AmchurCard>
      </Stack>
      <Stack width="67%">Buttons go here.</Stack>
    </Stack>
  );
}

interface AllPatientsListViewProps {
  allPatients: PatientList;
  startVisit: (patientId: PatientId) => void;
}

function AllPatientsListView({
  allPatients,
  startVisit,
}: AllPatientsListViewProps) {
  return (
    <Stack spacing={2} width="100%">
      {allPatients.map((patient) => (
        <PatientView
          patient={patient}
          key={patient.patientId}
          startVisit={startVisit}
        />
      ))}
    </Stack>
  );
}

interface PatientViewProps {
  patient: Partial<Patient>;
  startVisit: (patientId: PatientId) => void;
}

function PatientView({ patient, startVisit }: PatientViewProps) {
  const patientId = {
    patientId: patient.patientId || "",
    patientIdType: patient.patientIdType || "",
  };

  const onClick = nullary(startVisit, patientId);

  const cnic = toFormattedCnic(patient.patientId || "");
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Box width="60%" justifyContent="flex-start">
        <Typography textAlign="start">
          {patient.firstName} {patient.lastName}
        </Typography>
      </Box>
      <Typography>{cnic}</Typography>
      <Button onClick={onClick}>Check in</Button>
    </Stack>
  );
}

interface GreeterSearchViewProps {
  searchedPatients: PatientList;
  onPatientSearch: (patientId: PatientId) => void;
  onPatientAdd: (id: string) => void;
}
function GreeterSearchView({
  searchedPatients,
  onPatientSearch,
  onPatientAdd,
}: GreeterSearchViewProps) {
  const [patientId, setPatientId] = useState("");
  const onSearch = nullary(onPatientSearch, {
    patientId,
    patientIdType: "PKCNI",
  });
  const onAdd = nullary(onPatientAdd, patientId);

  return (
    <Stack alignItems="flex-start" spacing="10px" width="100%">
      <Stack direction="row" spacing="5px">
        <PatientIdField
          patientId={patientId}
          setPatientId={setPatientId}
          required={true}
          disabled={false}
        />
        <IconButton aria-label="search" onClick={onSearch}>
          <SearchIcon />
        </IconButton>
      </Stack>
      {searchedPatients.length === 0 && patientId.length > 0 && (
        <Typography sx={{ fontStyle: "italic" }}>No patient found.</Typography>
      )}
      {searchedPatients.length > 0 && (
        <AllPatientsListView
          allPatients={searchedPatients}
          startVisit={() => {}}
        />
      )}
      <Button variant="contained" startIcon={<AddIcon />} onClick={onAdd}>
        <FormattedMessage id="greeterDashboard.addNewPatient" />
      </Button>
    </Stack>
  );
}
export default GreeterDashboardUiView;
