import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface StdButtonProps {
  onClick: () => void;
  size?: "small" | "medium" | "large";
}
export function CancelButton({ onClick, size = "large" }: StdButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      startIcon={<CancelIcon />}
      size={size}
      color="error"
      data-testid="cancel-button"
    >
      <FormattedMessage id="common.cancel" />
    </Button>
  );
}

export function OkButton({ onClick, size = "large" }: StdButtonProps) {
  return (
    <Button
      variant="contained"
      startIcon={<CheckCircleIcon />}
      size={size}
      onClick={onClick}
      data-testid="ok-button"
    >
      <FormattedMessage id="common.ok" />
    </Button>
  );
}
interface NavButtonProps {
  route: string;
  size?: "small" | "medium" | "large";
}

export function NavigationCancelButton({ route, ...props }: NavButtonProps) {
  const navigate = useNavigate();
  const onClick = () => navigate(route);
  return <CancelButton onClick={onClick} {...props} />;
}
