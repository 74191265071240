import { type Theme, useTheme, SxProps } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

// this is a wrapper around the material card component that
// applies specific app appearance.

// 'secondary' cards use the primary color. this is intentional.
function chooseSecondaryColor(
  theme: Theme,
  fade: "none" | "faded" | "veryFaded" | undefined
) {
  switch (fade) {
    case "faded":
      return theme.palette.primaryFaded.main;
    case "veryFaded":
      return theme.palette.primaryVeryFaded.main;
    default:
      return theme.palette.primary.main;
  }
}
export interface AmchurCardProps {
  title: string;
  role: "primary" | "secondary";
  titleAlign?: "center" | "left";
  fade?: "none" | "faded" | "veryFaded";
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}
function AmchurCard({
  title,
  role,
  titleAlign,
  fade,
  sx,
  children,
}: AmchurCardProps) {
  const theme = useTheme();
  // yes these are intentionally swapped
  const backgroundColor =
    role === "primary"
      ? theme.palette.secondary.main
      : chooseSecondaryColor(theme, fade);

  const textAlign = titleAlign || (role === "primary" ? "center" : "left");
  const fullSx = { width: "100%", ...sx };
  return (
    <Card sx={fullSx}>
      <CardHeader
        titleTypographyProps={{ variant: "h4", textAlign: textAlign }}
        title={title}
        sx={{
          backgroundColor: backgroundColor,
          paddingY: "6px",
          minHeight: "1.5em",
        }}
      />
      <CardContent
        sx={{
          overflowY: "scroll",
        }}
      >
        {" "}
        {children}
      </CardContent>
    </Card>
  );
}

export default AmchurCard;
