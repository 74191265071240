import * as R from "ramda";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeNils } from "shared-utils";
import { AppDispatch } from "../../state/store";
import {
  useClinicPatientsQuery,
  PatientId,
  useLazyPatientQuery,
} from "../../graphql/generated";
import { setPatientId } from "../../state/currentPatientSlice";
import useStartVisit from "../../hooks/useStartVisit";
import GreeterDashboardUiView, {
  type PatientList,
} from "./GreeterDashboardUiView";

function GreeterDashboardRoute() {
  const { data } = useClinicPatientsQuery({ clinicId: "12345" });
  const [searchPatient, searchedPatientData] = useLazyPatientQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { startTheVisit } = useStartVisit();

  const startVisitForClinic = R.curry(startTheVisit)("12345");

  // TODO: consistently use useCallback here.
  const searchedPatients = useMemo(() => {
    return removeNils([searchedPatientData?.data?.patient]);
  }, [searchedPatientData]);

  const onPatientSearch = (patientId: PatientId) => {
    searchPatient({
      id: patientId.patientId,
      patientIdType: patientId.patientIdType,
    })
      .then(() => {})
      .catch((e) => {
        console.log(e.message);
      });
  };
  const onPatientAdd = (patientId: string) => {
    dispatch(setPatientId(patientId));
    navigate("/create-patient");
  };

  const patients: PatientList = removeNils(data?.clinic?.patients) || [];

  return (
    <GreeterDashboardUiView
      allPatients={patients}
      searchedPatients={searchedPatients}
      startVisit={startVisitForClinic}
      onPatientSearch={onPatientSearch}
      onPatientAdd={onPatientAdd}
    />
  );
}

export default GreeterDashboardRoute;
