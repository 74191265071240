import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import SimpleHeader from "../components/SimpleHeader";

const MainAppRoute = () => {
  return (
    <>
      <Stack height="100%">
        <SimpleHeader />
        <Outlet />
      </Stack>
    </>
  );
};

export default MainAppRoute;
