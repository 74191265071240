import { createNewSiteNoteFromFile } from "../domain/siteNoteDomain";
import { createApiThunk } from "./amchurMiddleware";

export interface UploadSiteNotesParams {
  files: File[];
}

export const uploadSiteNotesMiddleware = createApiThunk(
  "siteNote/upload",
  async (params: UploadSiteNotesParams, serviceBag, getState) => {
    const currentUser = getState().currentUser?.currentUser?.username;
    if (currentUser == null) {
      return false;
    }
    const promises = params.files.map((file) => {
      return createNewSiteNoteFromFile(file, currentUser, serviceBag);
    });
    await Promise.all(promises);
    return true;
  }
);

export const getSiteNoteDownloadUrlMiddleware = createApiThunk(
  "siteNote/downloadUrl",
  async (siteNoteId: string, serviceBag, getState) => {
    return await serviceBag.apiService.getSiteNoteDownloadUrls({
      ids: [siteNoteId],
    });
  }
);
