import * as R from "ramda";
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useIntl, FormattedMessage, type IntlShape } from "react-intl";
import { enqueueSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AppDispatch } from "../../state/store";
import { textChangeHandler } from "../../utils/eventUtils";
import { performCreateUser } from "../../middleware/authMiddleware";
import { type WebUserInput } from "../../graphql/generated";
import { selectHandler } from "../../utils/eventUtils";
import CenteredForm from "../../components/CenteredForm";
import DefinePasswordFieldSet from "../../components/DefinePasswordFieldSet";
import useDefinePasswordState, {
  DefinePasswordState,
} from "../../hooks/useDefinePasswordState";

interface CreateUserUiViewProps {
  username: string;
  setUsername: (username: string) => void;
  password: string;
  definePasswordState: DefinePasswordState;
  organization: string;
  setOrganization: (organization: string) => void;
  role: string;
  setRole: (role: string) => void;
  onSubmit: () => void;
}

// exported for testing
export function CreateUserUiView({
  username,
  setUsername,
  password,
  definePasswordState,
  organization,
  setOrganization,
  role,
  setRole,
  onSubmit,
}: CreateUserUiViewProps) {
  const intl = useIntl();
  const buttonEnabled = username.length > 0 && password && password.length > 0;

  console.log(`password here is ${password}`);
  const onRoleSelection = R.compose(setRole, selectHandler);
  return (
    <CenteredForm>
      <TextField
        required
        inputProps={{ "data-testid": "username" }}
        label={intl.formatMessage({ id: "createUser.username" })}
        variant="filled"
        value={username}
        onChange={textChangeHandler(setUsername)}
      />

      <DefinePasswordFieldSet
        username={username}
        definePasswordState={definePasswordState}
      />

      <Select
        variant="filled"
        label="Role"
        value={role}
        onChange={onRoleSelection}
      >
        <MenuItem key="user" value="user">
          Doctor/Recorder
        </MenuItem>
        <MenuItem key="smgr" value="smgr">
          Site Manager
        </MenuItem>
        <MenuItem key="trn" value="trn">
          Transcriber
        </MenuItem>
      </Select>

      <TextField
        inputProps={{ "data-testid": "organization" }}
        label={intl.formatMessage({ id: "createUser.organization" })}
        variant="filled"
        value={organization}
        onChange={textChangeHandler(setOrganization)}
      />

      <Box>
        <Button
          variant="contained"
          disabled={!buttonEnabled}
          data-testid="submit-button"
          onClick={onSubmit}
        >
          <FormattedMessage id="createUser.createUserButton" />
        </Button>
      </Box>
    </CenteredForm>
  );
}

interface CreateUserStateViewProps {
  onCreateUser: (
    username: string,
    password: string,
    role: string,
    organization: string
  ) => Promise<boolean>;
}

// exported for testing
export function CreateUserStateView({
  onCreateUser,
}: CreateUserStateViewProps) {
  const [username, setUsername] = useState("");
  const {
    acceptablePassword: password,
    definePasswordState,
    clearPassword,
  } = useDefinePasswordState();
  const [role, setRole] = useState("user");
  const [organization, setOrganization] = useState("");

  const onSubmit = () => {
    void onCreateUser(username, password, role, organization).then(
      (created) => {
        if (created) {
          setUsername("");
          clearPassword();
        }
      }
    );
  };

  return (
    <CreateUserUiView
      username={username}
      setUsername={setUsername}
      password={password}
      definePasswordState={definePasswordState}
      organization={organization}
      setOrganization={setOrganization}
      role={role}
      setRole={setRole}
      onSubmit={onSubmit}
    />
  );
}

async function dispatchPerformCreateUser(
  dispatch: AppDispatch,
  webUser: WebUserInput,
  intl: IntlShape
): Promise<boolean> {
  const result = await dispatch(performCreateUser(webUser));
  const created = performCreateUser.fulfilled.match(result);
  if (created) {
    enqueueSnackbar(intl.formatMessage({ id: "createUser.userCreated" }), {
      variant: "success",
    });
  }
  return created;
}

export default function CreateUserRoute() {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const onCreateUser = useCallback(
    (
      username: string,
      password: string,
      role: string,
      organization: string
    ) => {
      return dispatchPerformCreateUser(
        dispatch,
        { username, password, role, organization },
        intl
      );
    },
    [dispatch, intl]
  );
  return <CreateUserStateView onCreateUser={onCreateUser} />;
}
