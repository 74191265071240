import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { ListItem, ListSubheader, Typography } from "@mui/material";
import { type TranscriptionSection } from "../../hooks/useTranscriptionState";

export function KeywordChip({ text }: { text: string }) {
  return (
    <Grid xs={"auto"}>
      <Item>
        <Chip label={text} color="primary" />
      </Item>
    </Grid>
  );
}

interface TranscriptionSectionViewProps {
  keywords?: string[];
  section: TranscriptionSection;
}

function TranscriptionSectionView({
  keywords = [],
  section,
}: TranscriptionSectionViewProps) {
  const [header, lines] = section;
  const formattedHeader = header.replace(/_/g, " ");
  return (
    <>
      {/* // < direction="column" alignContent="left" spacing={2}> */}
      <ListSubheader>
        <Typography
          variant="h6"
          textAlign="left"
          marginTop="10px"
          textTransform="capitalize"
        >
          {formattedHeader}
        </Typography>
      </ListSubheader>
      {/* <Grid container spacing={2}>
        {keywords.map((keyword) => (
          <KeywordChip text={keyword} key={keyword} />
        ))}
      </Grid> */}

      {lines.map((line, ix) => (
        // <Stack direction="row" spacing={1}>
        // <Box alignItems="left" display="flex">
        <ListItem>
          <div style={{ textAlign: "left" }}>
            {line.speaker && (
              <Typography fontWeight="bold" component="span">
                {line.speaker}:&nbsp;
              </Typography>
            )}

            <Typography textAlign="left" key={ix} component="span">
              {line.text}
            </Typography>
          </div>
        </ListItem>
        // </Box>
        // </Stack>
      ))}
      {/* <FormattedTranscription transcription={transcription}/> */}
      {/* // </Stack> */}
    </>
  );
}

export default TranscriptionSectionView;
