import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { LoginState } from "../state/currentUserSlice";
import { type RootState } from "../state/reducers";
import { AppDispatch } from "../state/store";
import { performContinueSession } from "../middleware/authMiddleware";
import useInitialParams from "../hooks/useInitialParams";

function RequireLoggedInUser({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch<AppDispatch>();
  const loginState = useSelector(
    (state: RootState) => state.currentUser.loginState
  );
  const loggedInUser = useSelector(
    (state: RootState) => state.currentUser.currentUser
  );
  const { saveInitial } = useInitialParams();

  const needRedirect =
    loggedInUser == null || loginState === LoginState.LoggedOut;

  useEffect(() => {
    void dispatch(performContinueSession({}));
  }, [dispatch]);

  useEffect(() => {
    if (needRedirect) {
      saveInitial();
    }
  }, [saveInitial, needRedirect]);

  if (
    loginState === LoginState.LoggingIn ||
    loginState === LoginState.Unknown
  ) {
    return (
      <Stack>
        <Box height="100px" />
        <Box width="100%" height="100%">
          <CircularProgress />
        </Box>
      </Stack>
    );
  }
  if (needRedirect) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
}

export default RequireLoggedInUser;
