import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import SimpleHeader from "../../components/SimpleHeader";
import useVitalsState, { type VitalsState } from "../../hooks/useVitalsState";
import {
  Patient,
  VisitInput,
  VisitProgress,
  VitalsInput,
  useUpdateVisitDetailsMutation,
  useUpdateVisitProgressMutation,
} from "../../graphql/generated";
import { useServiceBag } from "../../services/ServiceBag";
import { type RootState } from "../../state/reducers";
import {
  NavigationCancelButton,
  OkButton,
} from "../../components/standardButtons";
import PainPointPairView from "../../components/PainPointPairView";
import useMultiSelect from "../../hooks/useMultiSelect";
import AmchurCard from "../../components/AmchurCard";
import VisistDetailsHeader from "../../components/VisistDetailsHeader";
import VitalsFormView from "./VitalsFormView";
import VisitDetailsFormView from "./VisitDetailsFormView";
import TagsSectionView from "./TagsSectionView";

function VisitDetailsRoute() {
  const clinicId = "12345";
  const [updateVisitDetails] = useUpdateVisitDetailsMutation();
  const [updateProgress] = useUpdateVisitProgressMutation();
  const { envService } = useServiceBag();
  const navigate = useNavigate();
  const patient = useSelector(
    (state: RootState) => state.currentPatient.patient
  );
  const visit = useSelector((state: RootState) => state.visit.visit);

  if (patient == null || visit == null) {
    return <div>no patient or visit</div>;
  }

  const advanceToPhysician = (visitId: string) => {
    const visitProgress: VisitProgress = {
      nextStep: "P",
    };
    return updateProgress({ clinicId, visitId, visitProgress })
      .then((result) => {
        console.log(`result: ${JSON.stringify(result)}`);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
  };

  const onCreateVisit = (vitalsInfo: VitalsInput, visitInfo: VisitInput) => {
    updateVisitDetails({
      patientId: patient.patientId,
      patientIdType: "PKCNI",
      visitId: visit.visitId,
      clinicId,
      createdAt: envService.currentTime(),
      visit: visitInfo,
      vitals: vitalsInfo,
    })
      .then(() => {
        advanceToPhysician(visit.visitId)
          .then(() => navigate("/medassist-dashboard"))
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // the stack and header will be moved to a wrapper route.
  return <VisitDetailStateView patient={patient} onSubmit={onCreateVisit} />;
}

interface VisitDetailStateProps {
  patient: Patient;
  onSubmit: (vitals: VitalsInput, visit: VisitInput) => void;
}

// exported to be available to tests.
export function VisitDetailStateView({
  onSubmit,
  patient,
}: VisitDetailStateProps) {
  const vitalsState = useVitalsState();
  const [primaryComplaint, setPrimaryComplaint] = useState("");
  const [noteToPhysician, setNoteToPhysician] = useState("");
  const [reasonForComing, setReasonForComing] = useState("");
  const {
    selectionList: frontSelectionList,
    toggleSelection: frontToggleSelection,
  } = useMultiSelect<number>([]);
  const {
    selectionList: backSelectionList,
    toggleSelection: backToggleSelection,
  } = useMultiSelect<number>([]);

  const onCreateVisit = () => {
    console.log("on create visit called!");
    console.log(`front pain points: ${JSON.stringify(frontSelectionList)}`);
    const vitalsInfo: VitalsInput = {
      height: vitalsState.height,
      weight: vitalsState.weight,
      systolicBloodPressure: vitalsState.systolic,
      diastolicBloodPressure: vitalsState.diastolic,
      heartRate: vitalsState.heartRate,
      respiratoryRate: vitalsState.respiratoryRate,
      bloodOxygen: vitalsState.bloodOxygen,
      temperature: vitalsState.temp,
      frontPainPoints: frontSelectionList,
      backPainPoints: backSelectionList,
    };
    const visitInfo: VisitInput = {
      chiefComplaint: primaryComplaint,
      noteToPhysician,
      reasonForComing,
    };
    onSubmit(vitalsInfo, visitInfo);
  };
  // the stack and header will be moved to a wrapper route.
  return (
    <Stack>
      <SimpleHeader />
      <VisitDetailUiView
        patient={patient}
        vitalsState={vitalsState}
        noteToPhysician={noteToPhysician}
        setNoteToPhysician={setNoteToPhysician}
        primaryComplaint={primaryComplaint}
        setPrimaryComplaint={setPrimaryComplaint}
        reasonForComing={reasonForComing}
        setReasonForComing={setReasonForComing}
        onSubmit={onCreateVisit}
        frontSelectionList={frontSelectionList}
        frontToggleSelection={frontToggleSelection}
        backSelectionList={backSelectionList}
        backToggleSelection={backToggleSelection}
      />
    </Stack>
  );
}

interface VisitDetailUiProps {
  patient: Patient;
  vitalsState: VitalsState;
  primaryComplaint: string;
  setPrimaryComplaint: (primaryComplaint: string) => void;
  noteToPhysician: string;
  setNoteToPhysician: (physicianNote: string) => void;
  reasonForComing: string;
  setReasonForComing: (reason: string) => void;
  frontSelectionList: number[];
  frontToggleSelection: (ix: number) => void;
  backSelectionList: number[];
  backToggleSelection: (ix: number) => void;
  onSubmit: () => void;
}

function VisitDetailUiView({
  patient,
  vitalsState,
  primaryComplaint,
  setPrimaryComplaint,
  noteToPhysician,
  setNoteToPhysician,
  reasonForComing,
  setReasonForComing,
  frontSelectionList,
  frontToggleSelection,
  backSelectionList,
  backToggleSelection,
  onSubmit,
}: VisitDetailUiProps) {
  const intl = useIntl();

  return (
    <Stack>
      <VisistDetailsHeader
        firstName={patient?.firstName}
        lastName={patient?.lastName}
      />
      <Box display="flex" mx="2em" mt="1em" columnGap={3}>
        <Grid container spacing={3} sx={{ flexBasis: "50%" }}>
          <Grid item xs={6} md={6}>
            <AmchurCard
              title={intl.formatMessage({ id: "visitDetails.title" })}
              role="primary"
            >
              <VisitDetailsFormView
                primaryComplaint={primaryComplaint}
                setPrimaryComplaint={setPrimaryComplaint}
                noteToPhysician={noteToPhysician}
                setNoteToPhysician={setNoteToPhysician}
                reasonForComing={reasonForComing}
                setReasonForComing={setReasonForComing}
              />
            </AmchurCard>
          </Grid>
          <Grid item xs={6} md={6}>
            <AmchurCard
              title={intl.formatMessage({ id: "tags.title" })}
              role="primary"
            >
              <TagsSectionView />
            </AmchurCard>
          </Grid>
          <Grid item xs={6} md={12}>
            <AmchurCard
              title={intl.formatMessage({ id: "demographics.title" })}
              role="primary"
            >
              <VitalsFormView vitalsState={vitalsState} />
            </AmchurCard>
          </Grid>
        </Grid>
        <Grid container sx={{ flexBasis: "50%" }}>
          <Grid item md={12}>
            <AmchurCard
              title={intl.formatMessage({ id: "painPoints.title" })}
              role="primary"
            >
              <PainPointPairView
                frontPainPoints={frontSelectionList}
                frontToggleSelection={frontToggleSelection}
                backPainPoints={backSelectionList}
                backToggleSelection={backToggleSelection}
              />
            </AmchurCard>
          </Grid>
        </Grid>
      </Box>

      <Stack direction="row" spacing="1em" textAlign="left" my="3em" mx="auto">
        <NavigationCancelButton route="/select-patient" />
        <OkButton onClick={onSubmit} />
      </Stack>
    </Stack>
  );
}

export default VisitDetailsRoute;
