import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClinicWebUserInfo } from "../graphql/generated";

export interface CurrentClinicSatate {
  clinic?: ClinicWebUserInfo;
}

const initialState: CurrentClinicSatate = {};

const currentClinicSlice = createSlice({
  name: "CurrentClinic",
  initialState,
  reducers: {
    setClinic: (state, action: PayloadAction<ClinicWebUserInfo>) => {
      state.clinic = action.payload;
    },
  },
});

export default currentClinicSlice.reducer;
export const { setClinic } = currentClinicSlice.actions;
