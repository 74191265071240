import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
//import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import { checkboxChangeHandler } from "../../utils/eventUtils";
import useAudioProcessing from "../../hooks/useAudioProcessing";

import { type RootState } from "../../state/reducers";
import useMemoizedRemoveNils from "../../hooks/useMemoizedRemoveNils";
import {
  PatientQuery,
  Vitals,
  usePatientMedicationQuery,
  usePatientQuery,
  useVitalsQuery,
} from "../../graphql/generated";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import VitalsBar from "./VitalsBar";
import VisitInteractionPanel, { MedicationInfo } from "./VisitInteractionPanel";

declare global {
  interface Window {
    socket: object;
  }
}

function VisitInProgressRoute() {
  const clinicId = "12345";

  const scrollRef = useRef<HTMLUListElement>(null);
  const [realTime, setRealTime] = useState(true);

  const navigate = useNavigate();

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current != null) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(scrollToBottom, [
    scrollToBottom,
    scrollRef?.current?.scrollHeight || 0,
  ]);

  // const onRefresh = useCallback(() => {
  //   checkAudioResults({ id: audioSessionId })
  //     .then((result) => {
  //       console.log(`AUDIO RESULT: ${JSON.stringify(result)}`);
  //       const audioResultData = result.data?.audioResults;
  //       if (
  //         !audioResultData ||
  //         (audioResultData.packets == null &&
  //           audioResultData?.questions == null)
  //       ) {
  //         enqueueSnackbar("No output found", {
  //           variant: "warning",
  //         });
  //       }
  //       console.log("parse packets");
  //       const packets = JSON.parse(
  //         audioResultData?.packets || "[]"
  //       ) as PacketHash;
  //       console.log(`packeys are ${JSON.stringify(packets)}`);
  //       console.log("parse questions");
  //       const questions = JSON.parse(
  //         audioResultData?.questions || "[]"
  //       ) as string[];
  //       console.log(`qs are ${JSON.stringify(questions)}`);
  //       console.log("appending what we got!");
  //       appendArrayToTranscription(packets, questions);
  //       scrollToBottom();
  //       console.log("DID append what we got!");
  //     })
  //     .catch((err) => {
  //       console.log(`AUDIO RESULT ERROR: ${JSON.stringify(err)}`);
  //       enqueueSnackbar("Unable to fetch output", {
  //         variant: "warning",
  //       });
  //     });
  // }, [appendArrayToTranscription, audioSessionId, checkAudioResults]);

  const visit = useSelector((state: RootState) => {
    return state.visit;
  });
  const visitId = visit?.visit?.visitId || "";
  useEffect(() => {
    console.log(`***** visitId is ${visitId}`);
  }, [visitId]);

  const { data: patientData } = usePatientQuery({
    id: visit.visit?.patientId || "",
    patientIdType: visit.visit?.patientIdType || "",
  });

  const { data: vitalsData } = useVitalsQuery({
    eventType: "V",
    eventId: visit?.visit?.visitId || "",
    patientId: visit.visit?.patientId || "",
    patientIdType: visit.visit?.patientIdType || "",
  });
  const vitals = vitalsData?.vitals;

  const { data: medicationData } = usePatientMedicationQuery({
    id: visit.visit?.patientId || "",
    patientIdType: visit.visit?.patientIdType || "",
  });
  const medications = useMemoizedRemoveNils(
    medicationData?.patient?.medications
  );

  const onDone = useCallback(() => {
    navigate("/plan");
  }, [navigate]);

  const {
    recordingInProgress,
    transcription,
    questions,
    startProcessing,
    stopProcessing,
    cancelProcessing,
    refreshResults,
    audioSessionId,
    assessmentReady,
  } = useAudioProcessing(clinicId, visitId, realTime);

  const readyToStartRecording = audioSessionId.length > 0;

  if (visit == null) {
    return <div>No current visit</div>;
  }

  return (
    <VisitInProgressUiView
      vitals={vitals}
      medications={medications}
      patient={patientData}
      onStart={startProcessing}
      onStop={stopProcessing}
      onCancel={cancelProcessing}
      onDone={onDone}
      onRefresh={refreshResults}
      transcription={transcription}
      questions={questions}
      recordingInProgress={recordingInProgress}
      readyToStart={readyToStartRecording}
      planReady={assessmentReady}
      realTime={realTime}
      setRealTime={setRealTime}
      ref={scrollRef}
    />
  );
}

interface VisitInProgressUiViewProps {
  patient: PatientQuery | undefined;
  vitals: Vitals | null | undefined;
  medications: MedicationInfo[];
  onStart: () => void;
  onStop: () => void;
  onCancel: () => void;
  onDone: () => void;
  onRefresh: () => void;
  recordingInProgress: boolean;
  planReady: boolean;
  readyToStart: boolean;
  transcription: TranscriptionSection[];
  questions: string[] | null;
  realTime: boolean;
  setRealTime: (realTime: boolean) => void;
}

function VisitInProgressUiViewInternal(
  {
    patient,
    vitals,
    medications,
    onStart,
    onStop,
    onCancel,
    onDone,
    onRefresh,
    recordingInProgress,
    transcription,
    questions,
    readyToStart,
    planReady,
    realTime,
    setRealTime,
  }: VisitInProgressUiViewProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  return (
    <Stack spacing="1em" ml="1em" height="100%">
      <VitalsBar vitals={vitals} patient={patient} />
      <ButtonRow
        onStart={onStart}
        onStop={onStop}
        onCancel={onCancel}
        onDone={onDone}
        onRefresh={onRefresh}
        recordingInProgress={recordingInProgress}
        readyToStart={readyToStart}
        enableDone={planReady}
        realTime={realTime}
        setRealTime={setRealTime}
      />
      <VisitInteractionPanel
        medications={medications}
        vitals={vitals}
        transcription={transcription}
        questions={questions}
        ref={ref}
      />
    </Stack>
  );
}

const VisitInProgressUiView = forwardRef(VisitInProgressUiViewInternal);

interface ButtonRowProps {
  onStart: () => void;
  onStop: () => void;
  onCancel: () => void;
  onDone: () => void;
  onRefresh: () => void;
  recordingInProgress: boolean;
  readyToStart: boolean;
  enableDone: boolean;
  realTime: boolean;
  setRealTime: (realTime: boolean) => void;
}
function ButtonRow({
  onStart,
  onStop,
  onCancel,
  onDone,
  onRefresh,
  recordingInProgress,
  readyToStart,
  enableDone,
  realTime,
  setRealTime,
}: ButtonRowProps) {
  return (
    <Stack direction="row" spacing="1em">
      <Button
        variant="contained"
        onClick={onStart}
        color="secondary"
        disabled={recordingInProgress || !readyToStart}
      >
        <PlayIcon />
        Start
      </Button>

      <Button
        variant="contained"
        onClick={onStop}
        disabled={!recordingInProgress}
      >
        <StopIcon />
        Stop
      </Button>
      <Button
        variant="contained"
        onClick={onCancel}
        color="error"
        disabled={!recordingInProgress}
      >
        <CancelIcon />
        Cancel
      </Button>

      <Button
        sx={{ ml: "2em" }}
        variant="contained"
        onClick={onDone}
        disabled={!enableDone}
      >
        Done
      </Button>
      <IconButton sx={{ ml: "2em" }} onClick={onRefresh}>
        <RefreshIcon />
      </IconButton>
      <RealTimeSwitch realTime={realTime} setRealTime={setRealTime} />
    </Stack>
  );
}

interface RealTimeSwitchProps {
  realTime: boolean;
  setRealTime: (realTime: boolean) => void;
}
function RealTimeSwitch({ realTime, setRealTime }: RealTimeSwitchProps) {
  const onChange = checkboxChangeHandler(setRealTime);
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={realTime} onChange={onChange} />}
        label="RT"
      />
    </FormGroup>
  );
}

export default VisitInProgressRoute;
