import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { SerializedError } from "@reduxjs/toolkit";
import {
  type ClientNotificationMessage,
  type GeneratedAssessment,
} from "shared-utils";
import { setAssessment } from "../state/assessmentSlice";
import {
  GenerateAssessmentMutation,
  useGenerateAssessmentMutation,
  useLazyAudioResultsQuery,
  useStartTranscriptionSessionMutation,
  useTranscribeAudioMutation,
} from "../graphql/generated";
import useTranscriptionState, { PacketHash } from "./useTranscriptionState";
import { useLazyWebSocket } from "./useWebSocket";
import useRecorder from "./useRecorder";

interface FinishAudioSessionResult {
  data?: GenerateAssessmentMutation;
  error?: ErrorResponse | SerializedError;
}

function useAudioProcessing(
  clinicId: string,
  visitId: string,
  realTime = true
) {
  const idRef = useRef<string | null>(null);
  const assessmentTimerRef = useRef<number | null>(null);

  const didStopRef = useRef(false);
  const [transcribeAudio] = useTranscribeAudioMutation();
  const [startSession, startSessionState] =
    useStartTranscriptionSessionMutation();
  const [generateAssessment, generateAssessmentState] =
    useGenerateAssessmentMutation();
  const [refreshAudioResults] = useLazyAudioResultsQuery();
  const audioSessionId =
    startSessionState.data?.startTranscriptionSession || "";

  const dispatch = useDispatch();

  const { transcription, questions, appendArrayToTranscription } =
    useTranscriptionState();

  const putAssessmentInStore = useCallback(
    (result: FinishAudioSessionResult) => {
      console.log("Assessment ready");
      const data = result.data;
      // TODO: handle error.
      if (data == null) {
        return;
      }
      const generatedAssessment = JSON.parse(
        data.generateAssessment || ""
      ) as GeneratedAssessment;
      dispatch(
        setAssessment({
          assessment: generatedAssessment.prediction?.assessment || {},
          notes: generatedAssessment.notes,
          diagnoses: generatedAssessment.prediction?.diseases || [],
          symptoms: generatedAssessment.symptoms || [],
        })
      );
    },
    [dispatch]
  );

  const handleTranscriptionUpdate = useCallback(
    (transcription?: PacketHash, questions?: string[]) => {
      if (assessmentTimerRef.current != null) {
        window.clearTimeout(assessmentTimerRef.current);
      }
      if (transcription && questions) {
        appendArrayToTranscription(transcription, questions);
      }
      // if we just got the last result, and the user has pressed stop,
      // we can fetch the assessment.
      if (didStopRef.current) {
        generateAssessment({
          clinicId: clinicId,
          visitId: visitId,
          sessionId: audioSessionId,
        })
          .then((result: FinishAudioSessionResult) => {
            putAssessmentInStore(result);
          })
          .catch((error) => {
            console.log(`error getting assessment: ${JSON.stringify(error)}`);
          });
      }
      return didStopRef.current;
    },
    [
      appendArrayToTranscription,
      audioSessionId,
      clinicId,
      generateAssessment,
      putAssessmentInStore,
      visitId,
    ]
  );

  // takes a transcript/question list sent via web socket, processes it, and
  // appends it to the current transcription.
  // returns whether we are finished.
  const handleTranscriptionUpdateFromWebSocket = useCallback(
    (msg: ClientNotificationMessage) => {
      if (msg.transcript) {
        const transcriptInfo = JSON.parse(msg.transcript);
        return handleTranscriptionUpdate(
          transcriptInfo.packets,
          transcriptInfo.questions
        );
      }
    },
    [handleTranscriptionUpdate]
  );

  // these methods start/stop listening to the websocket.
  const [startListeningToSocket, stopListeningToSocket] =
    useLazyWebSocket<ClientNotificationMessage>((m) => {
      if (handleTranscriptionUpdateFromWebSocket(m)) {
        console.log("shutting down socket");
        idRef.current = null;
        stopListeningToSocket();
      }
    });

  const processAudioAndTranscription = (data: string) => {
    // make sure we are listening at the web socket.
    if (idRef.current !== audioSessionId) {
      idRef.current = audioSessionId;
      startListeningToSocket({ clinic: audioSessionId || "", name: "AUDIO" });
    }

    return transcribeAudio({
      visitId: visitId,
      sessionId: audioSessionId,
      audio: data,
    }).then(() => {});
  };

  const onAudioDataReady = useCallback(processAudioAndTranscription, [
    audioSessionId,
    transcribeAudio,
    startListeningToSocket,
    visitId,
  ]);

  const onAudioFailure = useCallback(() => {
    console.log("recording failed ");
  }, []);

  const { recorder, recordingInProgress } = useRecorder(
    onAudioDataReady,
    onAudioFailure
  );

  const startTranscriptionSession = () => {
    if (visitId.length > 0) {
      console.log(`starting session for ${visitId}`);
      void startSession({ visitId: visitId });
    }
  };

  // start session when we have the visit loaded.
  useEffect(startTranscriptionSession, [startSession, visitId]);

  const startProcessing = useCallback(() => {
    void recorder.start(realTime ? 20 * 1000 : undefined);
  }, [realTime, recorder]);

  const refreshResults = useCallback(() => {
    refreshAudioResults({
      clinicId: clinicId,
      visitId: visitId,
    })
      .then((result) => {
        console.log(`got refresh results: ${JSON.stringify(result)}`);
        if (result.data?.visit) {
          const packets = JSON.parse(
            result.data.visit.transcription || "{}"
          ) as PacketHash;
          const questions = JSON.parse(
            result.data.visit.questions || "[]"
          ) as string[];
          handleTranscriptionUpdate(packets, questions);
        }
      })
      .catch((error) => {
        console.log(`error refreshing results: ${JSON.stringify(error)}`);
      });
  }, [clinicId, handleTranscriptionUpdate, refreshAudioResults, visitId]);

  const stopProcessing = useCallback(() => {
    recorder.stop();
    didStopRef.current = true;

    // in case the web socket is broken, set a timer to fetch the results
    // after giving the web socket time to function.
    assessmentTimerRef.current = window.setTimeout(refreshResults, 15 * 1000);
  }, [recorder, refreshResults]);

  const cancelProcessing = useCallback(() => {
    recorder.cancel();
  }, [recorder]);

  return {
    startProcessing,
    stopProcessing,
    cancelProcessing,
    refreshResults,
    recordingInProgress,
    transcription,
    questions,
    audioSessionId,
    assessmentReady: generateAssessmentState.isSuccess,
  };
}

export default useAudioProcessing;
