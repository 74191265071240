import { useIntl, IntlShape } from "react-intl";
import { DateTime } from "luxon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import VitalsIcon from "../../components/VitalsIcon";
import {
  isTemperatureAlertable,
  isBloodPressureAlertable,
  isHeartRateAlertable,
  isRespiratoryRateAlertable,
  isBloodOxygenAlertable,
} from "../../domain/vitalsDomain";
import { type PatientQuery, type Vitals } from "../../graphql/generated";
import IconOnCircle, { type IconProps } from "../../components/IconOnCircle";
import { useServiceBag } from "../../services/ServiceBag";

interface VitalsBarProps {
  vitals: Vitals | undefined | null;
  patient: PatientQuery | undefined;
}
function VitalsBar({ vitals, patient }: VitalsBarProps) {
  const intl = useIntl();

  return (
    <Box marginX="2em">
      <Stack
        direction="row"
        spacing="auto"
        alignItems="center"
        sx={{ mx: "1em" }}
      >
        <PatientPanel patient={patient} />{" "}
        {vitalsInfo(vitals, intl).map((vitalsInfo) => (
          <VisitInfoPanel {...vitalsInfo} key={vitalsInfo.title} />
        ))}
      </Stack>
    </Box>
  );
}

function vitalsInfo(
  vitals: Vitals | undefined | null,
  intl: IntlShape
): VisitInfoPanelParams[] {
  if (!vitals) {
    return [];
  }
  const bloodO2Text = `${vitals.bloodOxygen || ""}%`;
  const bloodPressureText = `${vitals.systolicBloodPressure || ""}/${
    vitals.diastolicBloodPressure || ""
  }`;

  return [
    {
      title: intl.formatMessage({ id: "demographics.temperature" }),
      value: vitals.temperature || "",
      unit: intl.formatMessage({ id: "demographics.temperature-unit" }),
      alert: isTemperatureAlertable(vitals.temperature),
    },
    {
      title: intl.formatMessage({ id: "demographics.blood-pressure" }),
      value: bloodPressureText,
      unit: intl.formatMessage({ id: "demographics.blood-pressure-unit" }),
      alert: isBloodPressureAlertable(
        vitals.systolicBloodPressure,
        vitals.diastolicBloodPressure
      ),
    },
    {
      title: intl.formatMessage({ id: "demographics.heartRate" }),
      value: vitals.heartRate || "",
      unit: intl.formatMessage({ id: "demographics.heartRate-unit" }),
      alert: isHeartRateAlertable(vitals.heartRate),
    },
    {
      title: intl.formatMessage({ id: "demographics.respiratoryRate" }),
      value: vitals.respiratoryRate || "",
      unit: intl.formatMessage({ id: "demographics.respiratoryRate-unit" }),
      alert: isRespiratoryRateAlertable(vitals.respiratoryRate),
    },
    {
      title: intl.formatMessage({ id: "demographics.bloodOxygen" }),
      value: bloodO2Text,
      unit: intl.formatMessage({ id: "demographics.bloodOxygen-unit" }),
      alert: isBloodOxygenAlertable(vitals.bloodOxygen),
    },
  ];
}

interface VisitInfoPanelParams {
  title: string;
  value: string | number;
  unit: string;
  showIcon?: boolean;
  alert?: boolean;
}
function VisitInfoPanel({
  title,
  value,
  unit,
  showIcon = true,
  alert = false,
}: VisitInfoPanelParams) {
  const variant = alert ? "outlined" : "elevation";
  const borderProps = alert
    ? { borderBlockColor: "red", borderInlineColor: "red" }
    : {};
  return (
    <Card variant={variant} sx={{ width: "10em", ...borderProps }}>
      <Stack alignItems="flex-start" mx="6px">
        <Typography variant="subtitle2" mx="auto" noWrap={true}>
          {title}
        </Typography>
        <Stack
          direction="row"
          alignContent="flex-end"
          alignItems="baseline"
          spacing="5px"
          ml="2px"
        >
          {showIcon && <VitalsIcon />}
          <Typography variant="datum">{value}</Typography>
          <Typography variant="caption">{unit}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

interface ageData {
  years: number;
  months: number;
}

export function calculateAge(birthDate: DateTime, currDate: DateTime): ageData {
  const ageDiff = currDate.diff(birthDate, ["years", "months", "days"]);
  // if anyone of the age componets are undefined, they'll be assigned zero
  let { years = 0, months = 0 } = ageDiff.toObject();
  // incase incorrect age is added by the patient/doctor
  if (years < 0 || months < 0) {
    years = 0;
    months = 0;
  }
  return { years, months };
}

export function toFormattedAge(
  { years, months }: ageData,
  intl: IntlShape
): string {
  // in case of incorrect age
  if (years === 0 && months === 0) {
    return "undefined";
  }
  const formattedAge = intl.formatMessage(
    { id: "ageFormat" },
    { years, months }
  );
  return formattedAge;
}

interface PatientPanelProps {
  patient: PatientQuery | undefined;
}

function PatientPanel({ patient }: PatientPanelProps) {
  const personIcon = (props: IconProps) => <PersonIcon {...props} />;
  const { envService } = useServiceBag();
  const intl = useIntl();

  // patient info variables
  const patientInfo = patient?.patient;
  const fullname =
    String(patientInfo?.firstName) + " " + String(patientInfo?.lastName);
  const birthday = patientInfo?.birthday;
  const birthdayLuxon = DateTime.fromISO(birthday);
  const currentTime = envService.currentTime();
  const { years, months } = calculateAge(birthdayLuxon, currentTime);
  const formattedAge = toFormattedAge({ years, months }, intl);

  // age etc. will go here as well.
  return (
    <Stack direction="row" spacing="6px">
      <IconOnCircle icon={personIcon} />
      <Typography fontWeight="bold">{fullname}</Typography>
      <Typography fontWeight="bold">{formattedAge}</Typography>
    </Stack>
  );
}

export default VitalsBar;
