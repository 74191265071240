import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";

interface PrimaryAppBarProps {
  readonly toggleMenu: () => void;
}

const PrimaryAppBar = ({ toggleMenu }: PrimaryAppBarProps) => {
  const avatarLink =
    "http://gravatar.com/avatar/b0d155fa58c23eca52dca1f13576a580";

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        >
          Sehat Sanjha
        </Typography>
        {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) => (
            <Button key={item} sx={{ color: "#fff" }}>
              {item}
            </Button>
          ))}
        </Box> */}

        <Avatar alt="Adrian" src={avatarLink} />
      </Toolbar>
    </AppBar>
  );
};

export default PrimaryAppBar;
