import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { type RootState } from "../state/reducers";
import {
  type SearchParams,
  searchParamKeys,
} from "../state/initialRequestSlice";
import { type AppDispatch } from "../state/store";
import {
  saveInitialParams,
  clearInitialRequest,
} from "../state/initialRequestSlice";

function collectSearchParams(params: URLSearchParams) {
  return searchParamKeys.reduce((acc: SearchParams, param) => {
    const value = params.get(param);
    if (value) {
      acc[param] = value;
    }
    return acc;
  }, {});
}

function useInitialParams() {
  const initialParamList = useSelector(
    (state: RootState) => state.initialRequest.searchParams
  );
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();

  // initial params are those stored previously from query params, overlayed
  // with current query params.
  const getInitial = useCallback(() => {
    return {
      ...initialParamList,
      ...collectSearchParams(searchParams),
    };
  }, [initialParamList, searchParams]);

  const saveInitial = useCallback(() => {
    dispatch(saveInitialParams(collectSearchParams(searchParams)));
  }, [dispatch, searchParams]);

  const clearInitial = useCallback(() => {
    dispatch(clearInitialRequest());
  }, [dispatch]);

  return { getInitial, saveInitial, clearInitial };
}

export default useInitialParams;
