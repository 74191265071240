export const userForSessionQuery = /* GraphQL */ `
  query userForSession {
    userForSession {
      username
      role
      organization
      clinics {
        clinicId
        name
        country
        province
        city
        role
        active
      }
    }
  }
`;
