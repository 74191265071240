import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import createRecorder from "./recorder";

export default function createEnvService() {
  return {
    // use this rather than calling new Date() directly so that tests can
    // mock a known value.
    currentTime: () => DateTime.now(),

    randomUUID: () => uuid(),

    recorder: createRecorder,
  };
}
export const envService = createEnvService();
export type EnvService = ReturnType<typeof createEnvService>;
