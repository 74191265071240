import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";

const hostURL = process.env.REACT_APP_GRAPHQL_URL || "";
export const client = new GraphQLClient(hostURL, { credentials: "include" });

export const api = createApi({
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
  baseQuery: graphqlRequestBaseQuery({ client }),
  endpoints: () => ({}),
});
