export const recordingStatuses = ["new", "assigned", "reviewed"] as const;
export type RecordingStatuses = typeof recordingStatuses[number];

export function limitToRecordingStatuses(role: string): RecordingStatuses {
  return recordingStatuses.includes(role as RecordingStatuses)
    ? (role as RecordingStatuses)
    : "new";
}

export const queueUrgencies = ["normal", "urgent", "lod"] as const;
export type QueueUrgencies = typeof queueUrgencies[number];

export function limitToQueueUrgencies(u: string): QueueUrgencies {
  return queueUrgencies.includes(u as QueueUrgencies)
    ? (u as QueueUrgencies)
    : "normal";
}
