import { type LoginPayload } from "../actions/authActions";
import { type WebUserInput } from "../gql/graphql";
import { createApiThunk } from "./amchurMiddleware";

export const performLogin = createApiThunk(
  "auth/performLogin",
  async (loginInfo: LoginPayload, serviceBag, _thunkApi) => {
    const api = serviceBag.apiService;
    return await api.login(loginInfo);
  }
);

export const performContinueSession = createApiThunk(
  "auth/continueSession",
  async (placeholder: unknown, serviceBag, _thunkApi) => {
    const api = serviceBag.apiService;
    try {
      return await api.userForSession();
    } catch (e) {
      console.log("continue session failed -- redirect");
      return;
    }
  }
);

export const performCreateUser = createApiThunk(
  "auth/performCreateUser",
  async (webUser: WebUserInput, serviceBag, _thunkApi) => {
    const api = serviceBag.apiService;
    return await api.createWebUser({ webUser: webUser });
  }
);

export const performLogout = createApiThunk(
  "auth/logout",
  async (placeholder: unknown, serviceBag, _thunkApi) => {
    const api = serviceBag.apiService;
    return await api.logout();
  }
);
