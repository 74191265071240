import { createSlice } from "@reduxjs/toolkit";
import { type WritableDraft } from "immer/dist/internal";
import { type WebUser } from "../gql/graphql";
import {
  performLogin,
  performContinueSession,
  performLogout,
} from "../middleware/authMiddleware";

export enum LoginState {
  Unknown,
  LoggedIn,
  LoggingIn,
  LoggedOut,
}

export interface CurrentUserState {
  currentUser?: WebUser;
  loginState: LoginState;
}

const initialState: CurrentUserState = {
  currentUser: undefined,
  loginState: LoginState.Unknown,
};

interface PayloadAction {
  payload?: unknown;
}

function completeLogin(
  state: WritableDraft<CurrentUserState>,
  action: PayloadAction
) {
  if (action.payload) {
    state.currentUser = action.payload as WebUser;
    state.loginState = LoginState.LoggedIn;
  } else {
    state.loginState = LoginState.LoggedOut;
  }
}
const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(performLogin.pending, (state) => {
      state.loginState = LoginState.LoggingIn;
    });
    builder.addCase(performLogin.fulfilled, completeLogin);

    builder.addCase(performContinueSession.pending, (state) => {
      state.loginState = LoginState.LoggingIn;
    });
    builder.addCase(performContinueSession.fulfilled, completeLogin);
    builder.addCase(performLogout.fulfilled, (state) => {
      state.loginState = LoginState.LoggedOut;
      state.currentUser = undefined;
    });
  },
});

export default currentUserSlice.reducer;
