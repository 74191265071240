import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import MainAppRoute from "../routes/MainAppRoute";
import InternalAppRoute from "../routes/InternalAppRoute";
import DataRoute from "../routes/DataRoute";
import ErrorPage from "../routes/ErrorPage";
import CreateUserRoute from "../routes/createUser/CreateUserRoute";
import PatientInfo from "../components/PatientInfo";
import LoginRoute from "../routes/login/LoginRoute";
import RecordRoute from "../routes/record/RecordRoute";
import RecordingRoute from "../routes/record/RecordingRoute";
import RecordingsRoute from "../routes/record/RecordingsRoute";
import SiteNotesRoute from "../routes/siteNotes/SiteNotesRoute";
import VitalsRoute from "../routes/vitals/VitalsRoute";
import CreatePatientRoute from "../routes/createPatient/CreatePatientRoute";
import VisitDetailsRoute from "../routes/visitDetails/VisitDetailsRoute";
import SelectPatientRoute from "../routes/selectPatient/SelectPatientRoute";
import VisitInProgressRoute from "../routes/visitInProgress/VisitInProgressRoute";
import GreeterDashboardRoute from "../routes/greeterDashboard/GreeterDashboardRoute";
import MedAssistDashboardRoute from "../routes/medAssistDashboard/MedAssistDashboardRoute";
import PhysicianDashboardRoute from "../routes/physicianDashboard/PhysicianDashboardRoute";
import PlanRoute from "../routes/planRoute/PlanRoute";
import SelectClinicRoute from "../routes/SelectClinic/SelectClinicRoute";
import RequireLoggedInUser from "./RequireLoggedInUser";

const ProtectedMainAppRoute = () => {
  return (
    // <RequireLoggedInUser>
    <MainAppRoute />
    // </RequireLoggedInUser>
  );
};

const ProtectedInternalAppRoute = () => {
  return (
    <RequireLoggedInUser>
      <InternalAppRoute />
    </RequireLoggedInUser>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/select-clinic" element={<SelectClinicRoute />} />
      <Route
        path="/"
        element={<ProtectedMainAppRoute />}
        errorElement={<ErrorPage />}
      >
        <Route path="/greeter-dashboard" element={<GreeterDashboardRoute />} />
        <Route
          path="/physician-dashboard"
          element={<PhysicianDashboardRoute />}
        />
        <Route
          path="/medassist-dashboard"
          element={<MedAssistDashboardRoute />}
        />

        <Route path="/create-patient" element={<CreatePatientRoute />} />
        <Route path="/plan" element={<PlanRoute />} />
        <Route path="/visit-details" element={<VisitDetailsRoute />} />
        <Route path="/select-patient" element={<SelectPatientRoute />} />
        <Route path="/visit-in-progress" element={<VisitInProgressRoute />} />
      </Route>
      <Route
        path="/"
        element={<ProtectedInternalAppRoute />}
        errorElement={<ErrorPage />}
      >
        <Route path="create-user" element={<CreateUserRoute />} />
        <Route path="data" element={<DataRoute />} />
        <Route path="patient" element={<PatientInfo />} />
        {/* verb: re-CORD, not the noun REC-ord */}
        <Route path="record" element={<RecordRoute />} />
        <Route path="recording/:recordingId" element={<RecordingRoute />} />
        <Route path="recordings" element={<RecordingsRoute />} />
        <Route path="site-notes" element={<SiteNotesRoute />} />
        <Route path="vitals" element={<VitalsRoute />} />
        <Route index element={<h1>index</h1>} />
      </Route>
    </>
  )
);

export default router;
