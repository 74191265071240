import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type Visit, type Vitals } from "../graphql/generated";

export interface VisitState {
  visit?: Visit;
  vitals?: Vitals;
}

const initialState: VisitState = {};

interface SetVisitParams {
  visit?: Visit;
  vitals?: Vitals;
}

const visitSlice = createSlice({
  name: "Visit",
  initialState,
  reducers: {
    setVisit: (state, action: PayloadAction<SetVisitParams>) => {
      console.log(
        `Set visit is called payload ${JSON.stringify(action.payload)}`
      );
      state.visit = action.payload.visit;
      state.vitals = action.payload.vitals;
    },
  },
});

export default visitSlice.reducer;
export const { setVisit } = visitSlice.actions;
