import * as R from "ramda";
import { useCallback, useEffect, useRef } from "react";
import { ClientNotificationMessage } from "shared-utils";
import { AppDispatch } from "../state/store";
import { ApiService } from "../graphql/apiService";

const wsUrl = process.env.REACT_APP_WS_URL || "ws://localhost:3001";

function processMessage(
  dispatch: AppDispatch,
  apiService: ApiService,
  msg: ClientNotificationMessage
) {
  // if this gets more complicated, we can have the app register handlers for different types
  // of refresh values. But for now we can keep it simple.
  if (msg.refresh) {
    for (const refresh of msg.refresh) {
      if (refresh === "queues") {
        console.log("refresh all the queues");
        dispatch(apiService.invalidateCachedQueryDataAction(["Visit"]));
      }
    }
  }
  console.log(`[message] Data received from server: ${JSON.stringify(msg)}`);
}

function useWebSocket<T>(
  args: Record<string, string>,
  process: (data: T) => void
) {
  const [start, stop] = useLazyWebSocket(process);

  useEffect(() => {
    start(args);
    return stop;
  }, [args, start, stop]);
}

function useLazyWebSocket<T>(process: (data: T) => void) {
  const ref = useRef<WebSocket | null>(null);

  const start = useCallback(
    (args: Record<string, string>) => {
      const queryString = new URLSearchParams(args).toString();
      console.log(`opening WS: ${queryString}`);
      ref.current = new WebSocket(`${wsUrl}?${queryString}`);

      ref.current.onopen = function (e) {
        console.log("it opened");
      };

      ref.current.onmessage = function (event) {
        console.log(
          `[message] Data received from server: ${JSON.stringify(event.data)}`
        );
        process(JSON.parse(event.data) as T);
      };

      ref.current.onclose = function (event) {
        console.log("it closed:");
      };
      ref.current.onerror = function (event) {
        console.log(`ws error: ${JSON.stringify(event)}`);
      };

      return () => {
        console.log("closing WS");
        ref.current?.close();
      };
    },
    [process]
  );

  const stop = useCallback(() => {
    ref.current?.close();
  }, []);
  return [start, stop] as const;
}
// this will evenutally take a clinic as an argument.
// This is used to listen for update (refresh) requests, which we will
// use to trigger the appropriate clear cache/refresh via RTK-Q.
function useWebSocketForUpdates(dispatch: AppDispatch, apiService: ApiService) {
  const process = R.curry(processMessage)(dispatch, apiService);
  useWebSocket<ClientNotificationMessage>(
    { clinic: "12345", name: "UPDATES" },
    process
  );
}

export default useWebSocket;
export { useWebSocketForUpdates, useLazyWebSocket };
